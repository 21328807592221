import dayjs from 'dayjs'

export const deepClone = (value: any) => {
  return JSON.parse(JSON.stringify(value))
}

/**
 * 文本转换
 * @param str
 */
export const processText = (str: string) => {
  if (str) {
    const regex = /(\d+\.\s*.+|[^\n]+)/g
    let match
    const matches = []

    while ((match = regex.exec(str)) !== null) {
      matches.push(match[0])
    }
    return matches
  }
  return []
}

//  时间格式转换
export const formatDate = (date: Date, format = 'YYYY-MM-DD' as string) => {
  return dayjs(date).format(format)
}

//  匹配是否为json
export const isJson = (str: string): boolean => {
  if (typeof str === 'string') {
    try {
      const obj = JSON.parse(str)
      // 等于这个条件说明就是JSON字符串 会返回true
      if (typeof obj === 'object' && obj) {
        return true
      } else {
        // 不是就返回false
        return false
      }
    } catch (e) {
      return false
    }
  }
  return false
}
//  快捷时间的转换
export const getDateRange = (type: number): { startAt: string; endAt: string } => {
  const todayEnd = dayjs().endOf('day')
  const todayStart = dayjs().startOf('day')
  let start: dayjs.Dayjs | string
  let end: dayjs.Dayjs | string
  switch (type) {
    case 1:
      start = todayStart
      end = todayEnd
      break
    case 2:
      start = todayStart.subtract(1, 'day')
      end = todayEnd.subtract(1, 'day')
      break
    case 3:
      start = todayStart.subtract(1, 'week')
      end = todayEnd
      break
    case 4:
      start = todayStart.subtract(1, 'month')
      end = todayEnd
      break
    case 5:
      start = todayStart.subtract(6, 'month')
      end = todayEnd
      break
    default:
      start = ''
      end = ''
      break
  }

  if (start && end) {
    return {
      // @ts-ignore
      startAt: formatDate(start.toDate()),
      // @ts-ignore
      endAt: formatDate(end.toDate())
    }
  }
  return { startAt: '', endAt: '' }
}

export const getRandom = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}
