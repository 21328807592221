import { SET_IS_OPEN_LOGIN, SET_USERINFO, CLEAR_USERINFO, SET_VIP_INFO, SET_IS_USERINFO_OPEN, SET_LOGIN_REGISTER_TYPE, SET_PASSWORD, SET_DEVICE_NO, SET_IS_CHANGE_PWD_OPEN, SET_IS_SHOW_TRIAL_PLAY, SET_IS_SHOW_TRAIL } from '../constants'
import { deepClone } from '@utils'

export interface RebateConfig {
  game_id: number;
  game_name: string;
  rebate: string;
  sys_games_id: number;
}

export interface GradeListItem {
  grade: number;
  gradeName: string;
  rechargeRebate: string;
  upRechargeCharge: string;
  upBetMoney: string;
  awardOnce: string;
  awardMonth: string;
  minMonthBet: string;
  day_withdraw_number: number;
  day_withdraw_money: string;
  withdraw_rate: string;
  withdraw_amount: number;
  rebate_config: RebateConfig[];
  weekly_bonus: number;
  monthly_bonus: number;
  min_monthly_number: number;
  min_per_week_number: number
}

export interface VipInfo {
  icon: number;
  username: string;
  totalDeposit: string;
  totalBetMoney: string;
  monthBetMoney: string;
  grade: number;
  gradeName: string;
  nextGrade: number;
  nextGradeName: string;
  rate: number;
  day_withdraw_money: string;
  day_withdraw_number: number;
  gradeList: GradeListItem[];
  nextLevelItem: GradeListItem
  upRechargeCharge: string;
}

interface InitUserInfo {
  uid: number;
  username: string;
  balance: string;
  thirdGameBalance: string;
  grade: number;
  recommend?: string;
  icon?: number;
  prize_push?: number;
  hand_transfer?: number;
  status?: number;
  token: string;
  refreshtoken?: string;
  vipInfo: VipInfo;
  user_type: number;
  total_rake_amount: number;
  yesterday_rake_amount: number;
  is_indentified: number;
  mobile: string;
}

interface State extends InitUserInfo {
  isOpenLogin: boolean;
  isUserInfoOpen: boolean;
  loginRegisterType: boolean;
  isOpenPassword: boolean;
  passwordSuccess: boolean;
  isChangePwdOpen: boolean;
  isShowTrialPlay: boolean;
  isShowTrail: boolean
}

interface Action {
    type: string;
    value: any;
}

const storageUserInfo = (localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo') as string)) || null

const initUserInfo: InitUserInfo = {
  uid: 0,
  username: '',
  balance: '0.00',
  thirdGameBalance: '0.00',
  grade: 0,
  recommend: '',
  icon: 1,
  prize_push: 1,
  hand_transfer: 0,
  status: 1,
  token: localStorage.getItem('token') || '',
  refreshtoken: '',
  vipInfo: {} as VipInfo,
  user_type: 0, // user_type: 0 表示普通用户, 1 表示试玩用户
  total_rake_amount: 0, //  历史总返水金额
  yesterday_rake_amount: 0, //  昨天总返水金额
  is_indentified: 0, //  用户是否完善信息，0：未完善，1：已完善
  mobile: '' //  用户手机号
}

const handleUserinfo = storageUserInfo || initUserInfo

const initValue: State = {
  isOpenLogin: false,
  isUserInfoOpen: false,
  loginRegisterType: 1,
  isOpenPassword: false,
  passwordSuccess: false,
  isChangePwdOpen: false,
  isShowTrialPlay: false,
  isShowTrail: false, // 试玩游戏
  deviceNo: '',
  ...handleUserinfo
}

export default (state: State = initValue, action: Action) => {
  let _state = deepClone(state)
  switch (action.type) {
    case SET_IS_OPEN_LOGIN:
      _state.isOpenLogin = action.value
      return _state
    case SET_IS_USERINFO_OPEN:
      _state.isUserInfoOpen = action.value
      return _state
    case SET_USERINFO:
      _state = { ..._state, ...action.value }
      localStorage.setItem('userInfo', JSON.stringify(_state))
      localStorage.setItem('token', _state.token)
      return _state
    case CLEAR_USERINFO:
      _state = { ..._state, ...initUserInfo, token: '' }
      return _state
    case SET_VIP_INFO:
      _state.vipInfo = action.value
      return _state
    case SET_LOGIN_REGISTER_TYPE:
      _state.loginRegisterType = action.value
      return _state
    case SET_PASSWORD:
      _state.isOpenPassword = action.value
      return _state
    case SET_DEVICE_NO:
      _state.deviceNo = action.value
      return _state
    case SET_IS_CHANGE_PWD_OPEN:
      _state.isChangePwdOpen = action.value
      return _state
    case SET_IS_SHOW_TRIAL_PLAY:
      _state.isShowTrialPlay = action.value
      return _state
    case SET_IS_SHOW_TRAIL:
      _state.isShowTrail = action.value
      return _state
    default:
      return _state
  }
}
