import { SET_GAME_CATEGORY, SET_GAME_BET_RECORD } from '../constants'
import { deepClone } from '@utils'

export interface GameCategory {
  id: number;
  category_name: string;
  alias: string;
}

interface State {
  gameCategory: GameCategory[] | [];
  gameBetRecord: any;
}

interface Action {
    type: string;
    value: any;
}

const initValue: State = {
  gameCategory: [],
  gameBetRecord: {}
}

export default (state: State = initValue, action: Action) => {
  const _state = deepClone(state)
  switch (action.type) {
    case SET_GAME_CATEGORY:
      _state.gameCategory = action.value
      return _state
    case SET_GAME_BET_RECORD:
      _state.gameBetRecord = action.value
      return _state
    default:
      return _state
  }
}
