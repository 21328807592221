import { ListElement, MenuGameListItem } from '@/types/game'
import {
  SET_ANNOUNCEMENT,
  SET_BANNER,
  SET_GAME_TYPE_INFO,
  SET_GAME_LIST,
  SET_HAS_CLOSE_ANNOUNCEMENT,
  SET_HOT_GAME,
  SET_RECENT_GAME
} from '../constants'

import { deepClone } from '@utils'

export interface AnnouncementItem {
  content: string;
  id: number;
  img: string;
  title: string;
  jump_url: string;
}

export interface BannerItem {
  created_at: string;
  h5_image: string;
  id: number;
  is_delete: number;
  name: string;
  pc_image: string;
  status: number;
  updated_at: string
  url?: string;
  key?: string;
  type: number;
  h5_content?: string;
  pc_content?: string;
  own_page?: string;
  game_category?: string;
  game_alias?: string;
  game_code?: string,
  is_pinned_top: number
  activity_id: string
}

export interface SubGameItem {
  gameCode: number;
  image_path: string;
  gameName: string;
  image: string;
  gameAlias?: string;
  gameCategory?: string;
  platformAlias?: string;
  trial_line_id: number;
}

interface SubGameList {
  count: number;
  list: SubGameItem[];
  page: number;
  pageCount: number;
  perPage: number;
}

export interface GameTypeInfo {
  cgameId: number;
  content: string;
  gameAlias: string;
  gameId: number;
  gameImage: string;
  gameName: string;
  gameStatus: number;
  gameUri: string;
  hasLobby: boolean;
  isShowOnSport: number;
  mobileDisplay: number;
  pcBigImage: string;
  pcSmallImage: string;
  platformAlias: string;
  sportsImage: string
  subGameItem: any[]
  vice_title: string;
  is_sub_games: number;
}

interface GameTypeInfoList {
  [key: string]: GameTypeInfo;
}

export interface State {
    announcement: AnnouncementItem[];
    banner: BannerItem[];
    gameTypeInfo: GameTypeInfoList[];
    subGame: SubGameList;
    gameList: MenuGameListItem[];
    hotGameList: MenuGameListItem;
    hasCloseAnnouncement: boolean;
    recentGames: ListElement[];
}

interface Action {
    type: string;
    value: any;
}

const initValue: State = {
  banner: [],
  gameTypeInfo: [], // 游戏大类
  announcement: [],
  hasCloseAnnouncement: false,
  subGame: {
    count: 0,
    list: [],
    page: 1,
    pageCount: 0,
    perPage: 10
  },
  gameList: [],
  hotGameList: {} as MenuGameListItem,
  recentGames: []
}

export default (state: State = initValue, action: Action) => {
  const _state = deepClone(state)
  switch (action.type) {
    case SET_ANNOUNCEMENT:
      _state.announcement = action.value
      return _state
    case SET_HAS_CLOSE_ANNOUNCEMENT:
      _state.hasCloseAnnouncement = action.value
      return _state
    case SET_BANNER:
      _state.banner = action.value
      return _state
    case SET_GAME_TYPE_INFO:
      _state.gameTypeInfo = action.value
      return _state
    // case SET_SLOT_GAME:
    //   _state.subGame = action.value
    //   return _state
    case SET_GAME_LIST:
      _state.gameList = action.value
      return _state
    case SET_HOT_GAME:
      _state.hotGameList = action.value
      return _state
    case SET_RECENT_GAME:
      _state.recentGames = action.value
      return _state
    default:
      return _state
  }
}
