import {
  SET_EXTRA_BONUS,
  SET_DAILY_TASK,
  SET_FIRST_RECHARGE_ACTIVITY,
  SET_IS_OPEN_WHEEL,
  SET_IS_BONUS,
  SET_IS_CHECK,
  SET_BONUS_CODE,
  SET_PDD_ACTIVITY,
  SET_PDD_ATTEND,
  SET_PDD_ACTIVITY_OPEN,
  SET_BIG_WHEEL,
  SET_IS_BIG_WHELL_FROM_SIDE_BAR,
  SET_GOLD_REWARD,
  SET_GOLD_AVAILABLE_EXCHANGE_BALANCE,
  SET_APP_DOWNLOAD_DATA
} from '../constants'
import { deepClone } from '@utils'
import type { PddActivityData, PddAttendData, GoldReward, AppDownLoadTypes } from '@/types/promo'
import type { MoneysItem } from '@hooks'

//  充值-固定金额
export interface FixedJsonTypes {
  recharge_money: string;
  award: string
}

//  任务通道
export interface PayInfoItem {
  categoryId?: number
  channelName?: string
  handselPercent?: string
  id: number
  maxMoney?: string
  minMoney?: string
  platformId?: number
  platformName?: string
  platformTypeId?: number
  remark: string
  settingId?: number
  moneys: MoneysItem[]
}
export interface ExtraBonusDataList {
  id: number;
  name: string;
  images: string;
  pay_id: string;
  amount: string;
  rebate: string;
  number: number
  start_date: string;
  end_date: string;
  status: number;
  is_delete: number;
  created_at: string;
  updated_at: string;
  recharge_number: number;
  surplus_date: string;
  pay_info: PayInfoItem[];
  fixed_json?: FixedJsonTypes[];
  total_deposit?: number;
  bet_code_multiple?: number;
  check_type: number
}

interface ExtraBonusData {
  count: number;
  list: ExtraBonusDataList[] | [];
  page: number;
  perPage: number;
  pageCount: number;
}

export interface DailyTaskList {
  id: number;
  name: string,
  games_id: number;
  sub_games_id: number;
  bet_number: number,
  images: string;
  bet_amount: string;
  task_number: number;
  start_date: string;
  end_date: string;
  status: number;
  is_delete: number;
  created_at: string;
  updated_at: string;
  user_number: number;
  surplus_date: number;
  remainingCount: number;
  currentBetCount: number;
  finishCount: number;
  bet_money: string;
  game_code: string;
  gameAlias: string;
  platformAlias: string;
  activity_type: string
}

interface DailyTaskData {
  count: number;
  list: DailyTaskList[] | [];
  page: number;
  perPage: number;
  pageCount: number;
}

interface FirstRechargeItem {
  id: number;
  name: string;
  start_date: string;
  end_date: string;
  min_amount: string;
  rate: string;
  h5_image: string;
  pc_image: string;
  status: number;
  is_delete: number;
  created_at: string;
  updated_at: string;
}

interface State {
  isOpenWheel: boolean;
  extraBonusData: ExtraBonusData;
  dailyTaskData: DailyTaskData;
  firstRechargeData: FirstRechargeItem;
  isBonus: boolean;

  isBonusCode: boolean;
  checkMark: {
    isCheck: boolean;
    checkCount: number
  },
  pddActivity: PddActivityData,
  pddAttend: PddAttendData,
  isPddActivityOpen: boolean,
  bigWheel: any,
  isBgWheelFromSideBar: boolean
  goldRewardData: GoldReward
  goldAvailAbleExchangeBalance: string,
  appDownData: AppDownLoadTypes
}

interface Action {
    type: string;
    value: any;
}

const initData = {
  count: 1,
  list: [],
  page: 1,
  perPage: 30,
  pageCount: 1
}

const initValue: State = {
  isOpenWheel: false,
  extraBonusData: initData,
  dailyTaskData: initData,
  goldRewardData: {} as GoldReward,
  firstRechargeData: {} as FirstRechargeItem,
  //  这个标识用来表示奖金页面充值
  isBonus: false,
  checkMark: {
    isCheck: false,
    checkCount: 0
  },
  isBonusCode: false,
  pddActivity: {} as PddActivityData,
  pddAttend: {} as PddAttendData,
  bigWheel: {} as any,
  isPddActivityOpen: false,
  isBgWheelFromSideBar: false,
  goldAvailAbleExchangeBalance: '0.00',
  appDownData: {} as AppDownLoadTypes
}

export default (state: State = initValue, action: Action) => {
  const _state = deepClone(state)
  switch (action.type) {
    case SET_EXTRA_BONUS:
      _state.extraBonusData = action.value
      return _state
    case SET_DAILY_TASK:
      _state.dailyTaskData = action.value
      return _state
    case SET_FIRST_RECHARGE_ACTIVITY:
      _state.firstRechargeData = action.value
      return _state
    case SET_IS_OPEN_WHEEL:
      _state.isOpenWheel = action.value
      return _state
    case SET_IS_BONUS:
      _state.isBonus = action.value
      return _state
    case SET_IS_CHECK:
      _state.checkMark = action.value
      return _state
    case SET_BONUS_CODE:
      _state.isBonusCode = action.value
      return _state
    case SET_PDD_ACTIVITY:
      _state.pddActivity = action.value
      return _state
    case SET_BIG_WHEEL:
      _state.bigWheel = action.value
      return _state
    case SET_PDD_ATTEND:
      _state.pddAttend = action.value
      return _state
    case SET_PDD_ACTIVITY_OPEN:
      _state.isPddActivityOpen = action.value
      return _state
    case SET_IS_BIG_WHELL_FROM_SIDE_BAR:
      _state.isBgWheelFromSideBar = action.value
      return _state
    case SET_GOLD_REWARD:
      _state.goldRewardData = action.value
      return _state
    case SET_GOLD_AVAILABLE_EXCHANGE_BALANCE:
      _state.goldAvailAbleExchangeBalance = action.value
      return _state
    case SET_APP_DOWNLOAD_DATA:
      _state.appDownData = { ..._state.appDownData, ...action.value } // 设置下载app活动的数据
      return _state
    default:
      return _state
  }
}
