import { combineReducers } from 'redux'

import webConfigs from './webConfigs'
import home from './home'
import user from './user'
import wallet from './wallet'
import activities from './activities'
import records from './records'

export * from './home'
export * from './user'
export * from './wallet'
export * from './activities'
export * from './records'
export * from './webConfigs'

export default combineReducers({
  webConfigs,
  home,
  user,
  wallet,
  activities,
  records
})
