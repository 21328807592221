import { SET_IS_OPEN_WALLET, SET_PAY_INFO, SET_WALLET_TYPE } from '../constants'
import { deepClone } from '@utils'

interface State {
  isOpenWallet: boolean;
  payInfo: any;
  walletType: number;
}

interface Action {
    type: string;
    value: any;
}

const initValue: State = {
  isOpenWallet: false,
  walletType: 1,
  payInfo: null
}

export default (state: State = initValue, action: Action) => {
  const _state = deepClone(state)
  switch (action.type) {
    case SET_IS_OPEN_WALLET:
      _state.isOpenWallet = action.value
      return _state
    case SET_WALLET_TYPE:
      _state.walletType = action.value
      return _state
    case SET_PAY_INFO:
      _state.payInfo = action.value
      return _state
    default:
      return _state
  }
}
